// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10852(4fb6422755)-C04/11/2024-17:38:59-B04/11/2024-17:42:55' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10852(4fb6422755)-C04/11/2024-17:38:59-B04/11/2024-17:42:55",
  branch: "master",
  latestTag: "6.1",
  revCount: "10852",
  shortHash: "4fb6422755",
  longHash: "4fb642275541ed9a1c5507108243271e45f8b09f",
  dateCommit: "04/11/2024-17:38:59",
  dateBuild: "04/11/2024-17:42:55",
  buildType: "Ansible",
  } ;
